<template>
  <v-dialog
    v-model="dialog"
    :persistent="disabledRule"
    max-width="600"
    transition="dialog-bottom-transition"
  >
    <v-overlay
      absolute
      :value="loading"
    >
      <v-progress-circular
        :size="50"
        color="white"
        indeterminate
      />
    </v-overlay>
    <v-card>
      <v-toolbar
        flat
        style="position: sticky; top: 0px; z-index: 1;"
      >
        <v-card-title
          class="font-weight-light"
        >
          {{ $t('actions|edit_categories') }}
        </v-card-title>
        <v-spacer />
        <v-icon
          size="24"
          class="mr-3"
          @click="closeDialog()"
        >
          mdi-close
        </v-icon>
      </v-toolbar>
      <v-card-text
        v-if="availableCategories.length"
        class="mt-3"
      >
        <div
          v-for="(item, i) in availableCategories"
          :key="i"
        >
          <v-text-field
            v-model="item.value"
            outlined
            dense
            :label="$t('mixed|category')"
            class="mx-10"
            :placeholder="item.value"
            :rules="[(v) => !!v || $t('expressions|text_required_field_cannot_be_empty')]"
            :append-icon="item.value ? 'mdi-close' : ''"
            :append-outer-icon="'mdi-trash-can'"
            @click:append="clearInput(i)"
            @click:append-outer="deleteCategories(item)"
          />
        </div>
      </v-card-text>
      <v-card-text
        v-else
      >
        {{ $t('mixed|no_available_categories') }}
      </v-card-text>
      <v-card-actions
        style="position: sticky; bottom: 0px; background-color: white;"
      >
        <v-spacer />
        <v-btn
          v-if="availableCategories.length"
          class="mb-3"
          color="primary"
          rounded
          small
          dense
          min-width="110"
          :disabled="disabledRule"
          @click="updateCategories(); closeDialog()"
        >
          {{ $t('actions|save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      actualTab: null,
      disabledRule: true,
      myTemplateCategories: [],
      companyTemplatesCategories: [],
      groupTemplatesCategories: [],
      lavviraTemplateCategories: []
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      templates: state => state.templates.templates,
      companyTemplates: state => state.companyTemplates.companyTemplates,
      groupTemplates: state => state.groupTemplates.groupTemplates,
      settings: state => state.settings.settings
    }),
    availableCategories () {
      let categories = []
      if (this.actualTab && this.actualTab.isMyTab) {
        categories = this.account.myTemplateCategories
      }
      if (this.actualTab && this.actualTab.isCompanyTab) {
        categories = this.company.companyTemplatesCategories
      }
      if (this.actualTab && this.actualTab.group) {
        const index = this.company.groups.findIndex(gr => gr._id === this.actualTab.group._id)
        categories = this.company.groups[index].groupTemplatesCategories
      }
      if (this.actualTab && this.actualTab.isLavviraTab) {
        categories = this.settings.lavviraSettings.lavviraTemplateCategories
      }
      return categories
    }
  },
  watch: {
    account: {
      handler (value) {
        this.myTemplateCategories = value.myTemplateCategories || {}
      },
      immediate: true
    },
    company: {
      handler (value) {
        if (value) {
          this.companyTemplatesCategories = value.companyTemplatesCategories || {}
        }
        if (this.actualTab && this.actualTab.group) {
          const index = this.company.groups.findIndex(gr => gr._id === this.actualTab.group._id)
          if (index !== -1) {
            this.groupTemplatesCategories = this.company.groups[index].groupTemplatesCategories
          }
        }
      },
      immediate: true
    },
    settings: {
      handler (value) {
        this.lavviraTemplateCategories = value.lavviraSettings.lavviraTemplateCategories || {}
      },
      immediate: true
    },
    availableCategories: {
      handler (value) {
        if (value.length) {
          value.forEach(text => {
            if (text.value === '') this.disabledRule = true
            else this.disabledRule = false
          })
        } else {
          this.disabledRule = false
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateTemplateCategories: 'account/updateTemplateCategoriesAcc',
      updateCompanyTemplateCategories: 'company/updateCompanyTemplateCategories',
      updateGroupTemplateCategories: 'company/updateGroupTemplateCategories',
      deleteTemplateCategories: 'account/deleteTemplateCategoriesAcc',
      removeCompanyTemplateCategories: 'company/removeCompanyTemplateCategories',
      removeGroupTemplateCategories: 'company/removeGroupTemplateCategories',
      deleteTemplateCategoryInTemplate: 'templates/deleteTemplateCategoryInTemplate',
      deleteCompanyCategoryInTemplate: 'companyTemplates/deleteCompanyCategoryInTemplate',
      deleteGroupCategoryInTemplate: 'groupTemplates/deleteGroupCategoryInTemplate',
      updateSettings: 'settings/updateSettings'
    }),
    async updateCategories () {
      if (this.actualTab && this.actualTab.isMyTab) {
        this.updateTemplateCategories({ myTemplateCategories: this.myTemplateCategories })
      } else if (this.actualTab && this.actualTab.isCompanyTab) {
        const _id = this.actualTab.company._id
        const payload = {
          companyTemplatesCategories: this.companyTemplatesCategories
        }
        try {
          await this.updateCompanyTemplateCategories({ _id, payload })
        } catch (error) {
          console.error(error, 'error')
        }
      } else if (this.actualTab && this.actualTab.isGroupTab) {
        const _id = this.actualTab.group._id
        const payload = {
          groupTemplatesCategories: this.groupTemplatesCategories
        }
        try {
          await this.updateGroupTemplateCategories({ _id, payload })
        } catch (error) {
          console.error(error, 'error')
        }
      } else if (this.actualTab && this.actualTab.isLavviraTab) {
        const payload = {
          updateLavviraDBTemplateCategoriesContent: true,
          lavviraTemplateCategories: this.lavviraTemplateCategories
        }
        try {
          await this.updateSettings({ payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        }
      }
    },
    async deleteCategories (item) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource'),
        type: 'warning'
      })
      if (!res) return
      if (res) {
        if (this.actualTab && this.actualTab.isMyTab) {
          this.loading = true
          const payload = {
            myTemplateCategories: item.value
          }
          try {
            await this.deleteTemplateCategories(payload)
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            await this.deleteCatInTemp(item)
            this.loading = false
          }
        } else if (this.actualTab && this.actualTab.isCompanyTab) {
          this.loading = true
          const _id = this.actualTab.company._id
          const payload = {
            companyTemplatesCategories: item.value
          }
          try {
            await this.removeCompanyTemplateCategories({ _id, payload })
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            await this.deleteCatInTemp(item)
            this.loading = false
          }
        } else if (this.actualTab && this.actualTab.isGroupTab) {
          this.loading = true
          const _id = this.actualTab.group._id
          const payload = {
            groupTemplatesCategories: item.value
          }
          try {
            await this.removeGroupTemplateCategories({ _id, payload })
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            await this.deleteCatInTemp(item)
            this.loading = false
          }
        } else if (this.actualTab && this.actualTab.isLavviraTab) {
          this.loading = true
          const payload = {
            lavviraTemplateCategoryToBeDeleted: true,
            lavviraTemplateCategory: item.value
          }
          try {
            await this.updateSettings({ payload })
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            await this.deleteCatInTemp(item)
            this.loading = false
          }
        }
      }
      if (this.availableCategories.length < 1) this.closeDialog()
    },
    async deleteCatInTemp (item) {
      this.loading = true
      let idArray = []
      if (this.actualTab && this.actualTab.isMyTab) {
        this.templates.forEach(temp => {
          if (temp.category === item.value) {
            idArray.push(temp._id)
          }
        })
        if (idArray.length) {
          idArray.forEach(_id => {
            try {
              const payload = {
                category: item.value
              }
              this.deleteTemplateCategoryInTemplate({
                _id,
                payload
              })
            } catch (e) {
              this.error = e
              console.error(this.error, 'error')
            } finally {
              this.loading = false
            }
          })
        }
      } else if (this.actualTab && this.actualTab.isCompanyTab) {
        this.companyTemplates.forEach(temp => {
          if (temp.category === item.value) {
            idArray.push(temp._id)
          }
        })
        if (idArray.length) {
          idArray.forEach(_id => {
            try {
              const payload = {
                category: item.value
              }
              this.deleteCompanyCategoryInTemplate({
                _id,
                payload
              })
            } catch (e) {
              this.error = e
              console.error(this.error, 'error')
            } finally {
              this.loading = false
            }
          })
        }
      } else if (this.actualTab && this.actualTab.isGroupTab) {
        this.groupTemplates.forEach(temp => {
          temp.sharedWith.groups.forEach(gr => {
            if (gr._id === this.actualTab.group._id && temp.category === item.value) {
              idArray.push(temp._id)
            }
          })
        })
        if (idArray.length) {
          idArray.forEach(_id => {
            try {
              const payload = {
                category: item.value
              }
              this.deleteGroupCategoryInTemplate({
                _id,
                payload
              })
            } catch (e) {
              this.error = e
              console.error(this.error, 'error')
            } finally {
              this.loading = false
            }
          })
        }
      }
    },
    removeField (i, field) {
      field.splice(i, 1)
    },
    onEditTemplateCategoriesEvent (emittedTab) {
      this.actualTab = emittedTab
      this.dialog = true
    },
    clearInput (index) {
      this.availableCategories[index].value = ''
    },
    closeDialog () {
      if (this.disabledRule) {
        this.addToast({
          title: this.$t('expressions|empty_fields_are_not_allowed_please_fill_or_delete'),
          snackbarColor: 'warning',
          color: 'white'
        })
      } else {
        this.dialog = false
      }
    }
  }
}

</script>
