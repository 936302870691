<template>
  <v-card
    id="list-card"
    ref="ready"
    class="mx-auto mt-n4"
    max-width="300"
    height="size"
    tile
    flat
  >
    <v-list
      nav
      dense
      :style="`height: ${$vuetify.breakpoint.height - 270 + 'px'}; overflow-y: auto`"
    >
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
          @click="emitSelection('')"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('common|all') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
          @click="emitSelection('none')"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('common|uncategorized') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div v-if="actualTab.isLavviraTab">
          <v-list-item
            v-for="(item, i) in settings.lavviraSettings.lavviraTemplateCategories"
            :key="i"
            :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
            @click="emitSelection(item.value)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="item.value"
              />
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="actualTab.isMyTab">
          <v-list-item
            v-for="(item, i) in account.myTemplateCategories"
            :key="i"
            :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
            @click="emitSelection(item.value)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="item.value"
              />
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="actualTab.isCompanyTab">
          <v-list-item
            v-for="(item, i) in company.companyTemplatesCategories"
            :key="i"
            :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
            @click="emitSelection(item.value)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="item.value"
              />
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="actualTab.isGroupTab">
          <v-list-item
            v-for="(item, i) in actualGroup.groupTemplatesCategories"
            :key="i"
            :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
            @click="emitSelection(item.value)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="item.value"
              />
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/EventBus'

export default {
  props: {
    actualTab: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: () => ({
        lavvira: false,
        lawyer: false
      })
    }
  },
  data () {
    return {
      selectedItem: '',
      size: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      settings: state => state.settings.settings
    }),
    optimusPrime () {
      return this.$vuetify.theme.primary
    },
    actualGroup () {
      let index
      if (this.actualTab && this.actualTab.group) {
        index = this.company.groups.findIndex(gr => gr._id === this.actualTab.group._id)
      }
      return this.company.groups[index]
    }
  },
  methods: {
    emitSelection (item) {
      EventBus.$emit('template-category-is-selected', item)
    }
  }
}
</script>
